import { createRouter, createWebHistory } from "vue-router";
import SignupPage from "@/components/SignupPage.vue";
import DownloadPage from "@/components/DownloadPage.vue";
import PassPreview from "@/components/PassPreview.vue";
import PolicyPage from "@/components/PolicyPage.vue";
import PageShell from "@/components/PageShell.vue";
import RatingPage from "@/components/RatingPage.vue";
import AddPointsPage from "@/components/AddPointsPage.vue";
import RecoveryPage from "@/components/RecoveryPage.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "pageShell",
      component: PageShell,
      children: [
        {
          path: "/:shortenedSignupPageId/rating",
          name: "ratingPage",
          component: RatingPage,
        },
        {
          path: "/:shortenedSignupPageId/:slugName?/download",
          name: "downloadPage",
          component: DownloadPage,
        },
        {
          path: "/:shortenedSignupPageId/:slugName?/addPoints",
          name: "addPointsPage",
          component: AddPointsPage,
        },
        {
          path: "/:shortenedSignupPageId/:slugName?/passPreview",
          name: "passPreview",
          component: PassPreview,
        },
        {
          path: "/:shortenedSignupPageId/policy",
          name: "policy",
          component: PolicyPage,
        },
        {
          path: "/:shortenedSignupPageId/recovery",
          name: "recoveryPage",
          component: RecoveryPage,
        },
        {
          path: "/:shortenedSignupPageId/:slugName?",
          name: "signupPage",
          component: SignupPage,
        },
      ],
    },
  ],
});

export default router;
