<template>
  <div class="relative w-full h-20">
    <c-image
      v-if="branding.coverImageId"
      :image-id="branding.coverImageId"
      mode="fill"
      class="w-full h-20 absolute bottom-0"
    />
    <div
      v-if="branding.coverImagePromotionsMode === 'stamps'"
      class="w-full h-20 absolute bottom-0 flex flex-col justify-evenly"
      :style="{
        color: branding.accentColor
      }"
    >
      <div class="flex flex-row justify-evenly">
        <div v-for="index in 5" :key="index" class="relative">
          <div
            class="rounded-full w-8 h-8 flex flex-row items-center justify-center opacity-50"
            :style="{
              'background-color': branding.backgroundColor
            }"
          ></div>
          <div
            class="absolute top-0 flex flex-row items-center justify-center w-8 h-8"
          >
            <c-image
              v-if="index <= 3"
              :image-id="branding.stampImageId"
              :width="12"
              :height="12"
              :colorize="branding.accentColor"
            />
            <div
              v-else
              class="text-accent text-2xl font-bold opacity-50"
              :style="{
                color: branding.accentColor
              }"
            >
              {{ index }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row justify-evenly">
        <div v-for="index in 5" :key="index" class="relative">
          <div
            class="rounded-full w-8 h-8 flex flex-row items-center justify-center opacity-50"
            :style="{
              'background-color': branding.backgroundColor
            }"
          ></div>
          <div
            class="absolute top-0 flex flex-row items-center justify-center w-8 h-8"
          >
            <c-image
              v-if="index === 5"
              :image-id="branding.rewardStampImageId"
              :width="12"
              :height="12"
              :colorize="branding.accentColor"
            />
            <div
              v-else
              class="text-accent text-2xl font-bold opacity-50"
              :style="{
                color: branding.accentColor
              }"
            >
              {{ index + 5 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CImage from '../../../core-ui/src/components/CImage.vue'

export default {
  name: 'StripImageTemplate',
  data() {
    return {}
  },
  props: {
    branding: {
      type: Object,
      required: true
    }
  },
  methods: {},
  computed: {},
  components: {
    CImage
  }
}
</script>

<style scoped></style>
