import { defineStore } from "pinia";
import api from "@/api.js";
import metaPixel from "@/metaPixel.js";

export const authStore = defineStore("auth", {
  state: () => ({
    passId: null,
    organization: {},
    organizationConfig: {},
    customFields: [],
    locations: [],
    branding: {},
    signupPage: {},
    signupElements: [],
    signupScript: {},
    initialised: false,
    currentCustomersByOrganizationId:
      JSON.parse(localStorage.getItem("currentCustomersByOrganizationId")) ||
      {},
    serviceWorkerRegistration: null,
    countryCode: "ES",
  }),
  getters: {
    locationsBySlug() {
      return this.locations.reduce((res, location) => {
        res[location.slug] = location;
        return res;
      }, {});
    },
    currentCustomer() {
      return this.currentCustomersByOrganizationId[this.organization.id] || {};
    },
  },
  actions: {
    async refreshSignupInfo() {
      let response = await api.get(
        `/signupPage/${this.router.currentRoute.value.params.shortenedSignupPageId}`
      );
      this.organization = response.data.organization;
      this.passId = response.data.passId;
      this.organizationConfig = response.data.organizationConfig;
      this.branding = response.data.branding;
      this.signupElements = response.data.signupElements;
      this.signupPage = response.data.signupPage;
      this.signupScripts = response.data.signupScripts;
      this.locations = response.data.locations;
      this.customFields = response.data.customFields;
      this.countryCode =
        this.locations.find((l) => !!l.countryCode)?.countryCode || "ES";
      this.initialised = true;
      if (this.signupScripts?.metaPixelId) {
        metaPixel.load(this.signupScripts.metaPixelId, this.router);
      }
    },
    async refreshCountryCode() {
      try {
        let response = await api.get("/countryCode");
        if (response?.data?.countryCode) {
          this.countryCode = response.data.countryCode;
        }
      } catch (e) {
        console.error(e);
      }
    },
    updateCurrentCustomer(currentCustomer) {
      this.currentCustomersByOrganizationId = {
        ...this.currentCustomersByOrganizationId,
        [currentCustomer.organizationId]: currentCustomer,
      };
      localStorage.setItem(
        "currentCustomersByOrganizationId",
        JSON.stringify(this.currentCustomersByOrganizationId)
      );
    },
    updateServiceWorkerRegistration(registration) {
      this.serviceWorkerRegistration = registration;
    },
  },
});
