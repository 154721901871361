<template>
  <div class="w-full">
    <mobile-template
      :branding="branding"
      :organization="organization"
      :point-types="pointTypes"
      :show-details-button="false"
      :show-platform-button="false"
    />
  </div>
</template>

<script>
import { authStore } from "@/stores/auth";
import { mapState } from "pinia";
import MobileTemplate from "../../../core-ui/src/components/MobileTemplate.vue";

export default {
  name: "PassPreview",
  data() {
    return {};
  },
  methods: {},
  components: {
    MobileTemplate,
  },
  computed: {
    ...mapState(authStore, ["organization", "branding"]),
    pointTypes() {
      return [
        {
          name: "Cafés",
        },
        {
          name: "Titular",
        },
      ];
    },
  },
};
</script>

<style scoped></style>
