<template>
  <div
    class="w-3/4 lg:w-1/2 xl:w-1/3 shadow flex items-center justify-center relative bg-white h-screen"
  >
    <div id="mobile" class="">
      <img src="../assets/mobile-template-4.jpg" class="w-[295px]" />
    </div>
    <div
      v-if="showPlatformButton"
      class="absolute z-10 top-0 mt-48 flex flex-row"
    >
      <div
        class="px-1 py-1 flex flex-row items-center justify-center rounded-full border cursor-pointer mr-3"
        :class="{ 'bg-black': platformSelected === 'android' }"
        @click="platformSelected = 'android'"
      >
        <c-icon
          :class="{ 'text-white': platformSelected === 'android' }"
          name="AndroidRound"
          size="1.3rem"
        />
      </div>
      <div
        class="px-1 py-1 flex flex-row items-center justify-center rounded-full border cursor-pointer"
        :class="{ 'bg-black': platformSelected === 'ios' }"
        @click="platformSelected = 'ios'"
      >
        <c-icon
          :class="{ 'text-white': platformSelected === 'ios' }"
          name="AppleRound"
          size="1.3rem"
        />
      </div>
    </div>
    <div
      class="absolute w-[220px] h-[330px] rounded-xl border border-gray-200 shadow-md overflow-hidden mt-2"
    >
      <div
        class="h-full"
        v-if="!showDetails"
        :style="{
          'background-color': branding.backgroundColor,
          color: getContrastColor
        }"
      >
        <div v-if="platformSelected === 'android'">
          <div class="flex flex-row items-center p-3">
            <div class="w-6 h-6 rounded-full mr-2 overflow-hidden">
              <c-image
                :image-id="branding.logoImageId"
                class="h-6 w-6"
                mode="fit"
              />
            </div>
            <div class="text-xs">{{ organization?.name }}</div>
          </div>
          <div class="border-t opacity-10" />
          <div class="font-light mx-3 mt-1 whitespace-pre">
            {{ branding.passTitle || 'Tarjeta de fidelidad' }}
          </div>
          <div class="mx-3 mt-2 font-light flex justify-between">
            <div class="text-xs">
              <div>
                {{ pointTypes?.[0]?.name }}
              </div>
              <div class="font-normal mt-1">3</div>
            </div>
            <div v-if="pointTypes?.[1]" class="text-xs text-right">
              <div>
                {{ pointTypes?.[1]?.name }}
              </div>
              <div class="font-normal mt-1">David Pérez</div>
            </div>
          </div>
          <div class="flex flex-col items-center justify-center mt-6">
            <div v-if="!branding.useBarcode" class="bg-white w-2/5 rounded-lg">
              <img src="../assets/qr.png" class="w-[90px]" />
            </div>
            <div
              v-else
              class="bg-white w-4/5 py-2 rounded-lg flex flex-col items-center justify-center mt-3"
            >
              <img src="../assets/barcode.png" class="w-[140px]" />
            </div>
            <div
              v-if="branding.useBarcodeAlternateText === 'phoneNumber'"
              class="text-black text-[0.6rem] mt-1"
            >
              +34666111222
            </div>
            <div
              v-else-if="branding.useBarcodeAlternateText === 'infoText'"
              class="text-black text-[0.6rem] mt-1"
            >
              {{ 'Pulsa ... o (i) para info' }}
            </div>
          </div>
          <strip-image-template
            :branding="branding"
            class="absolute-important bottom-0"
          />
        </div>
        <div v-else>
          <div
            v-if="branding.landscapeLogoImageId"
            class="flex flex-row justify-between items-center"
          >
            <div class="py-2 pl-2">
              <c-image
                :image-id="branding.landscapeLogoImageId"
                class="h-5 w-20"
                mode="fit"
              />
            </div>
            <div class="flex flex-col text-right text-xs mr-2">
              <div
                class="h-3 text-[0.55rem]"
                :style="{
                  color: branding.secondaryColor
                }"
              >
                Nombre
              </div>
              <div class="">Apellidos</div>
            </div>
          </div>
          <div v-else class="flex flex-row items-center p-3">
            <div class="w-6 h-6 rounded-full mr-2 overflow-hidden">
              <c-image
                :image-id="branding.logoImageId"
                class="h-6 w-6"
                mode="fit"
              />
            </div>
            <div class="text-xs">{{ organization?.name }}</div>
          </div>
          <strip-image-template :branding="branding" />

          <div class="border-t opacity-10" />
          <div class="mx-3 mt-1 font-light flex justify-between">
            <div class="text-xs">
              <div
                :style="{
                  color: branding.secondaryColor
                }"
              >
                {{ pointTypes[0]?.name }}
              </div>
              <div class="font-normal">x</div>
            </div>
            <div v-if="pointTypes[1]" class="text-xs text-right">
              <div
                :style="{
                  color: branding.secondaryColor
                }"
              >
                {{ pointTypes[1]?.name }}
              </div>
              <div class="font-normal">x</div>
            </div>
          </div>
          <div class="flex flex-row items-center justify-center mt-16">
            <div v-if="!branding.useBarcode" class="bg-white w-2/5 rounded-lg">
              <img src="../assets/qr.png" class="w-[90px]" />
            </div>
            <div
              v-else
              class="bg-white w-4/5 py-2 mt-3 rounded-lg flex flex-col items-center"
            >
              <img src="../assets/barcode.png" class="w-[150px]" />
              <div
                v-if="branding.useBarcodeAlternateText === 'phoneNumber'"
                class="text-black text-[0.6rem]"
              >
                +34666111222
              </div>
              <div
                v-else-if="branding.useBarcodeAlternateText === 'infoText'"
                class="text-black text-[0.6rem] mt-1"
              >
                {{ 'Pulsa ... o (i) para info' }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="p-3 bg-gray-100 h-full overflow-y-scroll">
        <div class="mb-3">
          <div>Cliente:</div>
          <div class="text-xs font-light">[Nombre del cliente]</div>
        </div>
        <div v-if="branding.passDescription" class="mb-3">
          <div>Tu tarjeta:</div>
          <div class="text-xs font-light whitespace-pre">
            {{ branding.passDescription }}
          </div>
        </div>
        <div>
          <div
            v-for="(link, index) in interestingLinks"
            :key="index"
            class="mt-2"
          >
            <div
              v-if="link.title && link.url"
              class="flex flex-row items-center"
            >
              <c-icon name="LanguageRound" size="1rem" />
              <div class="ml-2">
                {{ link.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showDetailsButton"
      class="border w-52 text-center cursor-pointer py-1 rounded-full absolute z-10 bottom-0 mb-48 text-xs"
      @click="showDetails = !showDetails"
    >
      {{ showDetails ? $t('core.close') : $t('brand-design.details') }}
    </div>
  </div>
</template>

<script>
import CImage from '../../../core-ui/src/components/CImage.vue'
import CIcon from '../../../core-ui/src/components/CIcon.vue'
import StripImageTemplate from './StripImageTemplate.vue'

export default {
  name: 'MobileTemplate',
  data() {
    return {
      showDetails: false,
      platformSelected: 'android'
    }
  },
  props: {
    branding: {
      type: Object,
      default: () => ({})
    },
    organization: {
      type: Object,
      default: () => ({})
    },
    interestingLinks: {
      type: Array,
      default: () => []
    },
    pointTypes: {
      type: Array,
      default: () => []
    },
    showPlatformButton: {
      type: Boolean,
      default: true
    },
    showDetailsButton: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    hexToRgb(hexColor) {
      // Convertir el color hexadecimal a RGB
      if (!hexColor) return null
      const hex = hexColor.replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => r + r + g + g + b + b
      )
      const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

      return rgb
        ? {
            r: parseInt(rgb[1], 16),
            g: parseInt(rgb[2], 16),
            b: parseInt(rgb[3], 16)
          }
        : null
    }
  },
  computed: {
    getContrastColor() {
      let rgbColor = this.hexToRgb(this.branding.backgroundColor)
      if (!rgbColor) return '#000000'
      // Calcular el índice de contraste del color
      const luminance =
        (0.299 * rgbColor.r + 0.587 * rgbColor.g + 0.114 * rgbColor.b) / 255
      const contrast = luminance > 0.5 ? '#000000' : '#FFFFFF'
      // Devolver el color de texto adecuado según el contraste
      return contrast
    }
  },
  components: {
    CImage,
    CIcon,
    StripImageTemplate
  }
}
</script>

<style scoped>
.absolute-important {
  position: absolute;
}
</style>
